  <template>
  <div :key="configureTrigger" class="launchpad-containers" :class="`deferred-content${loading ? ' loading' : ''}`">
    <TabView v-model:activeIndex="activeEditingContainerTabIndex" @tabChange="onTabChange">
      <TabPanel v-for="editingContainer in editingContainers" :key="editingContainer.APP_SUBDOMAIN">
        <template #header>
          <div class="flex flex-row align-items-center">
            <img v-if="selectedImage.iconBase64" :src="'data:image/png;base64,'+selectedImage.iconBase64" :alt="selectedImage.name" style="width:32px"/>
            <div v-if="editingContainer.node" class="ml-2 font-bold"><small>{{editingContainer.APP_SUBDOMAIN}}<br>@{{editingContainer.node}}</small></div>
            <div v-else class="ml-2 font-bold">{{editingContainer.APP_SUBDOMAIN}}</div>
            <i class="pi pi-plus absolute p-0 left-100 pl-2" @click="plusPanelClick" style="z-index:1"></i>
          </div>
        </template>
        <div style="max-width:600px">
          <div class="flex flex-row align-items-start">
            <Markdown class="line-height-2 pr-2 " v-if="selectedImage.about" :source="selectedImage.about" />
            <img v-if="selectedImage.iconBase64" class="hidden sm:block" :src="'data:image/png;base64,'+selectedImage.iconBase64" :alt="selectedImage.name" style="width:128px"/>
          </div>
          <div v-if="editingContainer.init && editingContainer.state==='up'" class="p-3 border-1 border-green-500 bg-green-100">
            <i class="pi pi-check text-green-500"></i> Die Anwendung wurde gestartet. Klicken Sie auf Öffnen, um zu Ihrem Container zu wechseln.
          </div>
          <div v-else class="p-3 border-1 border-blue-100 bg-blue-100">
            <i class="pi pi-info-circle"></i> Klicken Sie auf Starten, um Ihren Container hochzufahren.
          </div>
        </div>
        <Dialog v-model:visible="mapDisplaySettingsEditor[editingContainer.APP_SUBDOMAIN]" :modal="true" :close-on-escape="true" :dismissable-mask="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
          <template #header>
            <h3 class="m-0">Einstellungen</h3>
          </template>
          <div class="flex flex-column">
            <div>
              <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center py-2">
                <label for="name" class="flex-grow-1 font-bold uppercase">Benutzername</label>
                <div class="flex-grow-1 max-w-full lg:max-w-20rem">
                  <InputText :disabled="true" v-model="editingContainer['APP_USER']" class="w-full"></InputText>
                </div>
              </div>
            </div>
            <div v-for="key in Object.keys(editingContainer.unpacked||{})" v-bind:key="key">
              <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center py-2">
                <label for="name" class="flex-grow-1 font-bold uppercase">{{mapLabels[key]||key}}</label>
                <div class="flex-grow-1 max-w-full lg:max-w-20rem">
                  <InputText :disabled="editingContainer.init" v-model="editingContainer[key]" @change="onChangeContainer(editingContainer)" class="w-full"></InputText>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </TabPanel>
    </TabView>
    <div style="max-width:600px">
      <div class="flex flex-rows justify-content-between">
        <div class="flex flex-row justify-content-start">
          <Button label="Stop" v-if="editingContainers[activeEditingContainerTabIndex]?.state.match(/^(transition_down)|(up)/)"
                  :disabled="editingContainers[activeEditingContainerTabIndex]?.state.match(/^transition/)"
                  :icon="editingContainers[activeEditingContainerTabIndex]?.state==='transition_down' ? 'pi pi-sync pi-spin' : 'pi pi-stop-circle'"
                  @click="stopContainer(editingContainers[activeEditingContainerTabIndex])" />
          <Button label="Löschen" v-else-if="editingContainers[activeEditingContainerTabIndex]?.state!=='transition_down'"
                  :disabled="editingContainers[activeEditingContainerTabIndex]?.state.match(/^transition_/)"
                  :icon="editingContainers[activeEditingContainerTabIndex]?.state==='transition_destroy' ? 'pi pi-sync pi-spin' : 'pi pi-trash'"
                  @click="requestDestroyContainer($event,editingContainers[activeEditingContainerTabIndex])"
                  class="p-button-rounded p-button-danger" />
          <Button label="Domain"
                  :disabled="editingContainers[activeEditingContainerTabIndex]?.state.match(/^transition_/)"
                  :icon="'pi pi-arrow-up-right'"
                  @click="showDomains(editingContainers[activeEditingContainerTabIndex])"
                  class="p-button-rounded p-button-success ml-1" />
          <Button label="Konfiguration"
                  :disabled="editingContainers[activeEditingContainerTabIndex]?.state.match(/^transition_/)"
                  :icon="'pi pi-cog'"
                  @click="mapDisplaySettingsEditor[editingContainers[activeEditingContainerTabIndex]?.APP_SUBDOMAIN]=true"
                  class="p-button-rounded p-button-success ml-1" />
        </div>
        <div class="flex flex-row justify-content-end">
          <Button :disabled="editingContainers[activeEditingContainerTabIndex]?.state==='transition_up'"
                  v-show="editingContainers[activeEditingContainerTabIndex]?.state==='up'"
                  v-for="iconUrl of getIconUrls(editingContainers[activeEditingContainerTabIndex])"
                  :icon="'pi pi-'+iconUrl.icon"
                  @click="gotoSubdomain(editingContainers[activeEditingContainerTabIndex],iconUrl.url)"
                  :key="iconUrl.icon" class="mr-1"></Button>
          <Button class="mr-0 label-always" label="Starten" v-if="!editingContainers[activeEditingContainerTabIndex]?.state.match(/^up/)" :disabled="editingContainers[activeEditingContainerTabIndex]?.state.match(/^transition/)" :icon="editingContainers[activeEditingContainerTabIndex]?.state==='transition_up' ? 'pi pi-sync pi-spin' : 'pi pi-caret-right'" autofocus @click="startContainer(editingContainers[activeEditingContainerTabIndex])" />
          <Button label="Öffnen" v-else :icon="'pi pi-external-link'" autofocus @click="gotoSubdomain(editingContainers[activeEditingContainerTabIndex])" />
        </div>
      </div>
    </div>
    <Menu ref="plusMenu" :model="plusMenuCategories" :popup="true">
      <template #item="{item}">
        <li v-if="item.exec" class="p-menuitem" role="none">
          <div class="p-menuitem-link" role="menuitem" tabindex="0" @click="item.exec.apply()">
            <span class="p-menuitem-icon"></span><span class="p-menuitem-text">{{item.name}}</span>
          </div>
        </li>
        <li v-else class="p-menuitem" role="none">
          <div class="p-menuitem-link" role="menuitem" tabindex="0" @click="requestAddContainer(selectedImage)">
            <span class="p-menuitem-icon"></span><span class="p-menuitem-text">Neuer Container</span>
          </div>
        </li>
      </template>
    </Menu>
  </div>
</template>

<script>
import AuthGuestView from "@/components/AuthGuestView.vue";
import {getInitArgs} from "@/images";
import containersService from "@/containers-service";
import {randomHex} from "@/utils";
import {singletons} from "../../shared";
import SelectDomainDialog from "@/components/SelectDomainDialog.vue";
import ImagesPublicService from "@/images-public-service";
import Markdown from 'vue3-markdown-it';

export default {
  extends: AuthGuestView,
  name: 'LaunchpadContainersView',
  components: {
    Markdown
  },
  props: {
    msg: String,
    image: String,
    container: String
  },
  data() {
    return {
      listContainers:[],
      profile:{},
      initArgs:{},
      editingContainers:[],
      selectedImage:{},
      mapLabels:{
        "APP_USER":"Benutzername",
        "ADMIN_PWD":"Passwort"
      },
      plusMenuCategories:[{}],
      activeEditingContainerTabIndex:0,
      configureTrigger:1,
      isGuest:false,
      loading:true,
      mapDisplaySettingsEditor: {}
    }
  },
  async created() {
    this.profile = await this.deferred;
    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    this.isGuest = this.profile.username?.match(/^guest_/);
    let image = await new ImagesPublicService().index(this.image);
    await this.loadContainers();
    if (this.container) {
      let ix = this.listContainers.findIndex(c=>c.APP_SUBDOMAIN===this.container);
      if (ix>-1)
        this.activeEditingContainerTabIndex = ix;
    }
    if (image) {
      await this.requestContainers(image);
      this.attachWebsocketHandlers();
    }
    this.loading = false;
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    },
    async createContainer(selectedImage={}) {
      let {insertId} = (await containersService.create({list:[{
          APP_USER:this.profile.username,
          APP_SUBDOMAIN:randomHex(8),
          APP_IP:"dock-\\${APP_SUBDOMAIN}-ubuntu-1",
          ...await getInitArgs(selectedImage,this.profile),
          APP_IMAGE:selectedImage.name
        }]})).data||{insertId:0};
      if (insertId) {
        await this.loadContainers();
        await this.requestContainers(selectedImage,true);
      }
    },
    async requestContainers(selectedImage={},abortIfEmpty=false) {
      this.selectedImage = selectedImage;
      let existingContainers = this.listContainers.filter(container=>container.APP_IMAGE===selectedImage.name);
      if (existingContainers.length) {
        this.editingContainers = existingContainers;
        if (this.activeEditingContainerTabIndex>=existingContainers.length)
          this.activeEditingContainerTabIndex = 0;
      }
      else if (!abortIfEmpty)
        await this.createContainer(selectedImage);
    },
    async requestAddContainer(selectedImage={}) {
      await this.createContainer(selectedImage);
      this.activeEditingContainerTabIndex = this.editingContainers.length-1;
    },
    async onChangeContainer(_data) {
      //_data.APP_USER = (this.listUsers.find(user=>user.id===_data.userId)||{}).username||_data.APP_USER;
      let data = {};
      let targetKeys = [];
      for (let key in _data) {
        if ((_data.unpacked||{})[key]) {
          let target = _data.unpacked[key];
          data[target] = _data[target]||{};
          data[target][key] = _data[key];
          if (targetKeys.indexOf(target)===-1)
            targetKeys.push(target);
        }
      }
      targetKeys.forEach(key=>{
        data[key] = JSON.stringify(data[key]);
      });
      containersService.update(_data.id,data);
    },
    async loadContainers() {
      this.listContainers = (await containersService.index({},"self")).data||[];
      //this.listContainers = this.listContainers.filter(c=>c.APP_IMAGE===this.image)
      return this.listContainers;
    },
    async startContainer(selectedContainer) {
      selectedContainer.state="transition_up";
      try {
        await containersService.start({list:[selectedContainer]});
      } catch (err) {
        selectedContainer.state="";
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err.response?.data?.message, life: 10000})
      }
      selectedContainer.init=true;
    },
    async stopContainer(selectedContainer) {
      selectedContainer.state="transition_down";
      await containersService.stop({list:[selectedContainer]});
      selectedContainer.init=true;
    },
    async destroyContainer(selectedContainer) {
      selectedContainer.state="transition_destroy";
      await containersService.destroy({list:[selectedContainer]});
    },
    requestDestroyContainer($event, selectedContainer) {
      this.$confirm.require({
        target:$event.currentTarget,
        position: "center",
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher? Container-Daten werden gelöscht und können nicht wiederhergestellt werden.`,
        header: 'Container zerstören',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await this.destroyContainer(selectedContainer)
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
        }
      });
    },
    attachWebsocketHandlers() {
      for (let node of Object.keys(singletons.sockets)) {
        singletons.sockets[node].off('onContainersUp').on('onContainersUp', (listIds) => {
          this.listContainers.filter(item=>listIds.indexOf(item.id)>-1).forEach(item=>{
            item.state="up";
          });
          this.editingContainers.filter(({id})=>listIds.indexOf(id)>-1).forEach(c=>{
            c.state="up";
          });
        });
        singletons.sockets[node].off('onContainersDown').on('onContainersDown', (listIds) => {
          this.listContainers.filter(item=>listIds.indexOf(item.id)>-1).forEach(item=>{
            item.state="down";
          });
          this.editingContainers.filter(({id})=>listIds.indexOf(id)>-1).forEach(c=>{
            c.state="down";
          });
        });
        singletons.sockets[node].off('onContainersDestroy').on('onContainersDestroy', async (listContainers) => {
          let ix = this.editingContainers.findIndex(c=>listContainers.find(({id})=>id===c.id));
          this.activeEditingContainerTabIndex=0;
          this.editingContainers.splice(ix,1);
          if (!this.editingContainers.length)
            this.$router.push("/my");
          else {
            await this.loadContainers();
            this.configureTrigger++;
          }
        });
      }
    },
    gotoSubdomain(data,url="") {
      let {root,nodes} = singletons.cluster;
      let node = data.node || (nodes?.length ? nodes[0] : "m01");
      let targetHostUrl = root ? `${node}.${root}` : window.location.hostname;
      window.open(`${window.location.protocol}//${data.EXTERNAL_FQDN ? data.EXTERNAL_FQDN : `${data.APP_SUBDOMAIN}.${targetHostUrl}`}${url}`)
    },
    plusPanelClick(e) {
      this.$refs.plusMenu.toggle(e);
      e.stopImmediatePropagation();
    },
    showDomains(selectedContainer) {
      this.$dialog.open(SelectDomainDialog, {
        props: {
          header: 'Domain verknüpfen',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '50vw',
            '640px': '80vw',
            '460px': '100vw'
          }
        },
        data: {
          domainId:selectedContainer.domainId
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            try {
              let {selectedDomain} = data;
              let {data:updated} = await containersService.setContainerDomain(selectedContainer.id,selectedDomain.id);
              if (updated.changedRows) {
                if (selectedContainer.state==="up") {
                  await this.startContainer(selectedContainer);
                  if (selectedDomain.id)
                    this.$toast.add({severity:'success', summary: 'Domainverknüpfung', detail:"Domain erfolgreich verknüpft.", life: 3000});
                  else
                    this.$toast.add({severity:'warn', summary: 'Domainverknüpfung', detail:"Verknüpfung entfernt.", life: 3000});
                }
              }
              else
                this.$toast.add({severity:'warn', summary: 'Domainverknüpfung', detail:"Keine Änderung.", life: 3000});
              selectedContainer.domainId = selectedDomain.id;
              selectedContainer.EXTERNAL_FQDN = selectedDomain.fqdn;
            } catch (err) {
              const response = err.response?.data||{};
              this.$toast.add({severity:'error', summary: `Fehler ${response.status}`, detail:`${response.error}`, life: 3000})
            }
          }
        }
      });
    },
    getIconUrls(container={}) {
      let list = [];
      for (let k in container) {
        if (((container.unpacked||{})[k]||"")==='iconUrls') {
          list.push({icon:k,url:container[k]});
        }
      }
      return list;
    },
    onTabChange(e) {
      this.$router.push(`/my/${this.image}/${this.listContainers[e.index]?.APP_SUBDOMAIN||""}`)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul>li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
