<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <label>Benutzer</label>
      <Dropdown v-model="userId" :options="mapEnum['userId']" optionLabel="username" optionValue="id" placeholder="Wählen" />
      <label class="mt-3">FQDN</label>
      <InputText type="text" v-model="fqdn" class="flex-grow-1" placeholder="meine.subdomain.net" autofocus/>
      <label class="mt-3">Typ</label>
      <Dropdown v-model="type" :options="mapEnum['type']" optionLabel="name" optionValue="id" placeholder="Wählen" />
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text mr-2"/>
    <Button :disabled="!fqdn.length || !type" label="Ok" icon="pi pi-check" @click="requestCloseDialog" autofocus />
  </div>
</template>

<script>

import NewDomainDialog from "@/components/NewDomainDialog.vue";
import usersService from "@/users-service";

export default {
  inject: ['dialogRef'],
  extends: NewDomainDialog,
  name: "NewDomainAdminDialog",
  data() {
    return {
      userId:null,
      fqdn:"",
      type:"",
      mapEnum: {
        userId:[],type:[{id:1,name:"A"},{id:2,name:"CNAME"}],
      },
    }
  },
  async created() {
    await this.loadUsers();
    let params = this.dialogRef.data;
    if (params) {
      this.val = params.val;
    }
  },
  methods: {
    async loadUsers() {
      ({data:this.mapEnum.userId} = await usersService.index());
    },
    closeDialog(ok=true) {
      this.dialogRef.close(ok ? {
        fqdn:this.fqdn,
        type:this.type,
        userId:this.userId
      } : null);
    },
    isValid() {
      return this.fqdn.match(/^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/) && this.type>0;
    },
    requestCloseDialog() {
      if (this.isValid())
        this.closeDialog();
      else {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:"Ihre Eingaben sind nicht korrekt", life: 3000})
      }
    }
  }
}
</script>

<style scoped>

</style>