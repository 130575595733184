<template>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="p-datatable"><div class="p-datatable-header border-top-none">Braintree Payments Test</div></div>
    <div class="flex flex-column align-items-start mt-2 ml-1">
      <div id="dropin-container"></div>
      <Button :icon="senderIcon" label="CHF 10" @click="subscribe('test-edulaun-pro')"></Button>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import braintreeService from "@/braintree-service";

export default {
  extends: AuthView,
  name: 'PaymentTestView',
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:2,
      data: {},
      loading:true,
      senderIcon:"",
      paymentInstance:null
    }
  },
  async mounted() {
    this.updateSenderIcon();
    await this.deferred;
    var dropin = require('braintree-web-drop-in');
    let {data:authorization} = await braintreeService.getClientToken();
    this.paymentInstance = await dropin.create({
      authorization,
      container: '#dropin-container'
    });
    this.loading = false;
  },
  methods: {
    async pay(amount=10) {
      this.updateSenderIcon(true);
      let {success, transaction} = await new Promise((resolve)=>{
        this.paymentInstance.requestPaymentMethod(async (requestPaymentMethodErr, payload) => {
          let {data} = await braintreeService.checkout({nonce:payload.nonce,amount});
          resolve(data);
        });
      });
      console.log(success,transaction);
      this.updateSenderIcon();
    },
    async subscribe(planId="test-edulaun-pro") {
      this.updateSenderIcon(true);
      let {success, transaction} = await new Promise((resolve)=>{
        this.paymentInstance.requestPaymentMethod(async (requestPaymentMethodErr, payload) => {
          let {data} = await braintreeService.subscribe({nonce:payload.nonce,planId});
          resolve(data);
        });
      });
      console.log(success,transaction);
      this.updateSenderIcon();
    },
    updateSenderIcon(sending=false) {
      this.senderIcon = sending ? "pi pi-sync pi-spin" : "pi pi-credit-card";
    },
  }
}
</script>
