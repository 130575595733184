<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="searchValue" placeholder="Suche" @keyup.enter="onSearch" class="search"/>
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="searchValue" @click="searchValue=''; onSearch()"/>
        </span>
      </div>
      <Button v-if="profile.level>1" label="Import" :icon="uploadIcon" class="p-button-primary ml-1" @click="requestImportContainers"></Button>
      <Button v-if="profile.level>1 || profile.containerControls" label="Neuer&nbsp;Container" icon="pi pi-plus" class="p-button-primary ml-1" @click="requestCreateContainer"></Button>
    </div>
  </div>

  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <DataTable :value="listContainers" responsiveLayout="scroll" :class="'flex flex-column mb-7'" edit-mode="cell" :key="trigger" v-model:filters="mapFilters"
    v-model:selection="selectedContainers" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange">
      <template #empty>
        Keine Container verfügbar
      </template>
      <Column selectionMode="multiple" v-if="profile.level>1"></Column>
      <Column class="column-button" header="Steuerung" v-if="profile.level>1 || profile.containerControls">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-trash" :disabled="slotProps.data.state==='up'" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestRemoveContainers($event,[slotProps.data])"/>
            <Button :icon="slotProps.data.state==='transition_down' ? 'pi pi-sync pi-spin' : 'pi pi-stop-circle'" :disabled="slotProps.data.state!=='up'" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="stopContainers([slotProps.data])"/>
            <Button :icon="slotProps.data.state==='transition_up' ? 'pi pi-sync pi-spin' : 'pi pi-caret-right'" :disabled="slotProps.data.state==='up'" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="startContainers([slotProps.data])"/>
            <Button :icon="'pi pi-server'" :disabled="!slotProps.data.init" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="viewUpLog(slotProps.data)"/>
          </div>
        </template>
      </Column>
      <Column header="Status" v-else>
        <template #body="slotProps">
          <div>{{slotProps.data.state==='up' ? 'running' : 'off'}}</div>
        </template>
      </Column>
      <Column v-for="key in Object.keys(mapColumnsCategories)" :field="key" :header="mapColumnsCategories[key] || key" :key="key" class="p-cell-editing" :sortable="true">
        <template #body="{data,field}">
          <div v-if="!data.init && profile.level>1">
            <div v-if="key==='APP_IMAGE'" class="flex flex-row justify-content-between align-items-center">
              {{data[field]}}
            </div>
            <div v-else-if="key==='userId'" class="flex flex-row justify-content-between align-items-center">
              <Dropdown v-model="data[field]" :options="listUsers" optionLabel="username" optionValue="id" class="flex-grow-1" @change="onChangeContainer(data)"></Dropdown>
            </div>
            <div v-else-if="!key.match(/^stats/)" class="flex flex-row justify-content-between align-items-center">
              <InputText v-model="data[field]" class="flex-grow-1" @change="onChangeContainer(data)"></InputText>
            </div>
          </div>
          <div v-else-if="key==='userId'">
            {{(listUsers.find(user=>user.id===data[field])||{}).username}}
          </div>
          <div v-else>
            {{data[field]}}
          </div>
        </template>
      </Column>
      <Column bodyStyle="text-align: right" class="column-button">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-external-link" :disabled="slotProps.data.state!=='up'" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="gotoSubdomain(slotProps.data)"/>
            <Button v-for="icon of listIconUrls" v-bind:key="icon" :icon="'pi pi-'+icon" :disabled="slotProps.data.state!=='up'" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="gotoSubdomain(slotProps.data,slotProps.data[icon])"/>
            <Button :icon="slotProps.data.state==='create_image' ? 'pi pi-sync pi-spin' : 'pi pi-camera'" :disabled="slotProps.data.state==='create_image' || !slotProps.data.image?.clonable" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="createImage(slotProps.data)" v-if="profile.level>1"/>
            <Button icon="pi pi-ellipsis-h" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="editRow(slotProps.data)"/>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog v-model:visible="displayImportDialog" :modal="true" :close-on-escape="true" :dismissable-mask="true" :closable="false" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Container importieren</h3>
    </template>
    <p>Bitte wählen Sie das gewünschte Image.</p>
    <div class="flex flex-column">
      <Listbox v-model="selectedImage" :options="listImages" optionLabel="name" :multiple="false">
        <template #optiongroup="slotProps">
          <div class="flex align-items-center">
            <img v-if="slotProps.option.iconBase64" src="data:image/png;base64,{{slotProps.option.iconBase64}}" alt="Icon" style="width:32px"/>
            <div>{{ slotProps.option.label }}</div>
          </div>
        </template>
      </Listbox>
      <div class="mt-2" v-if="selectedImage">
        <i class="text-primary no-underline font-bold pi pi-check"></i>&nbsp;Eine passende Excel-Vorlage für den Upload können Sie <a @click.prevent="downloadTemplate(selectedImage.name)" href="#" class="text-primary no-underline font-bold"><i class="pi pi-download"></i>&nbsp;hier</a> herunterladen.</div>
    </div>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideImportDialog" class="p-button-text"/>
        <FileUpload :disabled="!selectedImage" class="ml-3 my-0" name="dates[]" :customUpload="true" @uploader="handleUpload" @select="updateUploadIcon(true)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Upload" :chooseIcon="uploadIcon">
          <template #empty></template>
        </FileUpload>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displaySelectSheets" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Container aus Datei importieren</h3>
    </template>
    <Listbox v-model="selectedSheets" :options="listSheets" optionLabel="name" :multiple="true"/>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideSelectSheets" class="p-button-text"/>
        <Button label="Import" icon="pi pi-check" @click="importSelectedSheets" autofocus />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displaySelectImage" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Neuer Container</h3>
    </template>
    <Listbox v-model="selectedImage" :options="listImages" optionLabel="name" :multiple="false">
      <template #option="slotProps">
        <div class="flex align-items-center">
          <img :src="'data:image/png;base64,'+((slotProps.option.iconBase64) ? slotProps.option.iconBase64 : 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII')" alt="Icon" style="width:64px"/>
          <div class="ml-2">{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Listbox>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideSelectImage" class="p-button-text"/>
        <Button :disabled="!selectedImage" label="Ok" icon="pi pi-check" @click="createContainer(selectedImage)" autofocus />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayRowEditor" :modal="true" :close-on-escape="true" :dismissable-mask="true" :closable="false" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Parameter</h3>
    </template>
    <div class="flex flex-column">
      <div v-for="key in Object.keys(editingRow.unpacked||{})" v-bind:key="key">
        <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2">
          <label for="name" class="w-2 flex-shrink-0 font-bold">{{key}}</label>
          <div v-if="!editingRow.init && profile.level>1" class="flex-grow-1">
            <InputText v-model="editingRow[key]" @change="onChangeContainer(editingRow)" class="w-full"></InputText>
          </div>
          <div v-else class="flex-grow-1">
            {{editingRow[key]}}
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <Sidebar v-model:visible="sidebarVisible" position="bottom" :modal="false" :showCloseIcon="false" :class="'toolbar'" :dismissable="false">
    <div class="flex flex-row justify-content-between my-3 mr-3">
      <Button label="Löschen" icon="pi pi-trash" :disabled="selectedContainers.filter(c=>c.state==='up').length" :class="'p-button-rounded p-button-danger ml-3'" @click="requestRemoveContainers($event,selectedContainers)"/>
      <div class="flex flex-row justify-content-end">
        <Button label="Stop" icon="pi pi-stop-circle" class="p-button-secondary mr-2" @click="stopContainers()" :disabled="!selectedContainers.filter(c=>c.state==='up').length"></Button>
        <Button label="Start" icon="pi pi-caret-right" class="p-button-primary" @click="startContainers()" :disabled="selectedContainers.filter(c=>c.state==='up').length===selectedContainers.length"></Button>
      </div>
    </div>
  </Sidebar>
  <ConfirmDialog group="remove-containers" class="remove-containers-dialog">
    <template #message="slotProps">
      <div class="flex flex-column w-full">
        <div class="flex flex-row align-items-center">
          <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
          <p class="pl-2">{{ slotProps.message.message }}</p>
        </div>
        <div class="flex flex-row align-items-center mt-3">
          <Checkbox v-model="destroyRemovedContainers" :binary="true" input-id="destroy-volumes" />
          <label for="destroy-volumes" class="ml-1">Volumes löschen</label>
        </div>
      </div>
    </template>
  </ConfirmDialog>
  <ConfirmDialog group="destroy-containers" class="destroy-containers-dialog">
    <template #message="slotProps">
      <div class="flex flex-column">
        <div class="flex flex-row align-items-center">
          <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
          <p class="pl-2">{{ slotProps.message.message }}</p>
        </div>
      </div>
    </template>
  </ConfirmDialog>
  <Dialog v-model:visible="displayUpLog" :modal="true" :close-on-escape="true" :dismissable-mask="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">{{displayUpLog.container?.APP_IMAGE}} {{displayUpLog.container?.APP_SUBDOMAIN}}</h3>
    </template>
    <pre>
      {{displayUpLog.log}}
    </pre>
  </Dialog>
</template>

<script>
import AuthView from "@/components/AuthView";
import containersService from "@/containers-service";
import {FilterMatchMode} from "primevue/api";
import imagesService from "@/images-service";
import {singletons} from "../../shared";
import {randomHex} from "@/utils";
import * as xlsx from "xlsx";
import usersService from "@/users-service";
import imageDownloadsService from "@/image-downloads-service";
import {getInitArgs} from "@/images";

export default {
  extends: AuthView,
  name: 'ContainersView',
  data() {
    return {
      requiredUserLevel:2,
      listContainers:[],
      mapColumnsCategories:{
        node: "Cluster Node",
        userId:"Benutzer",
        APP_SUBDOMAIN:"Subdomain",
        APP_IMAGE:"Image",
        statsCPU:"CPU",
        statsMem:"MEM",
        statsNet:"NET"
      },
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Container',url: '/containers'}
      ],
      searchValue:"",
      trigger:0,
      mapFilters:{
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      listImages:[],
      selectedContainers:null,
      sidebarVisible:false,
      displaySelectSheets:false,
      selectedSheets:null,
      listSheets:[],
      uploadIcon:"",
      destroyRemovedContainers:false,
      listUsers:[],
      profile:{},
      listIconUrls:[],
      displaySelectImage:false,
      selectedImage:null,
      displayRowEditor:false,
      editingRow:{},
      displayImportDialog:false,
      displayUpLog:false
    }
  },
  async created() {
    this.updateUploadIcon();
    this.profile = await this.deferred;
    this.listCrumbs[0].label = `Container${!this.profile.level ? ` von ${this.profile.username}`:""}`
    await this.loadData();
    this.manageIconColumns();
    this.trigger++;
    this.loading = false;
    if (this.profile.level>0)
      await this.loadUsers();
    else
      this.listUsers = [this.profile];
    if (this.profile.level>1 || this.profile.containerControls)
      await this.loadImages();
    this.trigger++;
    this.attachWebsocketHandlers();
  },
  methods: {
    manageIconColumns() {
      if (this.listContainers.length) {
        this.listIconUrls = [];
        let c = this.listContainers[0];
        for (let k in c) {
          if (((c.unpacked||{})[k]||"")==='iconUrls') {
            this.listIconUrls.push(k)
          }
        }
      }
    },
    attachWebsocketHandlers() {
      for (let node of Object.keys(singletons.sockets)) {
        singletons.sockets[node].off('onContainersDown').on('onContainersDown', (listIds) => {
          this.listContainers.filter(item=>listIds.indexOf(item.id)>-1).forEach(item=>{
            item.state="";
          });
          this.trigger++;
        });
        singletons.sockets[node].on('onContainersUp', (listIds) => {
          this.listContainers.filter(item=>listIds.indexOf(item.id)>-1).forEach(item=>{
            item.state="up";
          });
          this.trigger++;
        });
        singletons.sockets[node].off('onImageCreated').on('onImageCreated', ({containerId,imageName}) => {
          this.listContainers.filter(item=>parseInt(item.id)===parseInt(containerId)).forEach(item=>{
            item.state="";
          });
          this.loadImages();
          this.trigger++;
          this.$toast.add({severity:'success', summary: 'Image erstellt', detail:`Image '${imageName}' erstellt`, life: 3000});
        });
        singletons.sockets[node].off('onContainersDestroy').on('onContainersDestroy', (listIds) => {
          console.log("destroyed",listIds);
          this.loadData();
        });
        singletons.sockets[node].off('onDockerStats').on('onDockerStats', ({stats:mapStats}) => {
          for (let subdomain of Object.keys(mapStats)) {
            let c = this.listContainers.find(c=>c.APP_SUBDOMAIN===subdomain);
            if (c) {
              let stats = mapStats[subdomain];
              c.statsCPU = stats.CPUPerc||"-";
              c.statsMem = (stats.MemUsage||"").replace(/\s.*$/,"")||"-";
              c.statsNet = stats.NetIO||"-";
            }
          }
        });
      }
    },
    async loadImages() {
      this.listImages = (await imagesService.index()).data||[];
    },
    async loadUsers() {
      this.listUsers = (await usersService.index()).data||[];
    },
    async loadData() {
      this.listContainers = (await containersService.index()).data||[];
    },
    getNextUsername() {
      let i=1;
      let users = this.listContainers.map(({user})=>user);
      while (users.indexOf(`user_${i}`)!==-1)
        i++;
      return `user_${i}`;
    },
    requestCreateContainer() {
      this.displaySelectImage = true;
    },
    requestImportContainers() {
      this.displayImportDialog = true;
    },
    hideSelectImage() {
      this.displaySelectImage = false;
    },
    hideImportDialog() {
      this.displayImportDialog = false;
    },
    async createContainer(selectedImage={}) {
      this.hideSelectImage();
      await containersService.create({list:[{
        APP_USER:this.listUsers[this.listUsers.length-1].username,
        APP_SUBDOMAIN:randomHex(8),
        APP_IP:"dock-\\${APP_SUBDOMAIN}-ubuntu-1",
        ...await getInitArgs(selectedImage,this.listUsers[this.listUsers.length-1]),
        APP_IMAGE:selectedImage.name
      }]});
      this.loadData();
    },
    async createContainers(list) {
      try {
        await containersService.create({list});
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
      }
      this.loadData();
    },
    requestDestroyContainers(event,listContainers) {
      this.$confirm.require({
        target:event.currentTarget,
        position: "center",
        group:"destroy-containers",
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher? Container-Daten werden gelöscht und können nicht wiederhergestellt werden.`,
        header: 'Container zerstören',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            this.destroyContainers(listContainers)
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    requestRemoveContainers(event,listContainers) {
      this.$confirm.require({
        target:event.currentTarget,
        position: "center",
        group:"remove-containers",
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Container löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            if (this.destroyRemovedContainers)
              this.requestDestroyContainers(event,listContainers)
            else {
              await containersService.delete(listContainers.map(({id})=>id).join(","));
              this.unselectContainers();
            }
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    async onChangeContainer(_data) {
      _data.APP_USER = (this.listUsers.find(user=>user.id===_data.userId)||{}).username||_data.APP_USER;
      let data = {};
      for (let key in this.mapColumnsCategories) {
        if (!key.match(/^stats/))
          data[key] = _data[key];
      }
      let targetKeys = [];
      for (let key in _data) {
        if ((_data.unpacked||{})[key]) {
          let target = _data.unpacked[key];
          data[target] = _data[target]||{};
          data[target][key] = _data[key];
          if (targetKeys.indexOf(target)===-1)
            targetKeys.push(target);
        }
      }
      targetKeys.forEach(key=>{
        data[key] = JSON.stringify(data[key]);
      });
      containersService.update(_data.id,data);
    },
    onSearch() {
      this.trigger++;
      this.mapFilters.global.value = this.searchValue;
    },
    startContainers(list=this.selectedContainers) {
      containersService.start({list:list.map(item=>{delete item.image; return item})});
      list.forEach(container=>{
        container.state="transition_up";
        container.init=true;
      });
      this.unselectContainers();
    },
    stopContainers(list=this.selectedContainers) {
      containersService.stop({list:list.map(item=>{delete item.image; return item})});
      list.forEach(container=>container.state="transition_down");
      this.unselectContainers();
    },
    destroyContainers(list=this.selectedContainers) {
      containersService.destroy({list:list.map(item=>{delete item.image; return item})});
      list.forEach(container=>container.state="transition_down");
      this.unselectContainers();
      this.destroyRemovedContainers=false;
    },
    gotoSubdomain(data,url="") {
      let {root,nodes} = singletons.cluster;
      let node = data.node || (nodes?.length ? nodes[0] : "m01");
      let targetHostUrl = root ? `${node}.${root}` : window.location.hostname;
      window.open(`${window.location.protocol}//${data.EXTERNAL_FQDN ? data.EXTERNAL_FQDN : `${data.APP_SUBDOMAIN}.${targetHostUrl}`}${url}`)
    },
    createImage({id:containerId,APP_SUBDOMAIN}) {
      imagesService.create({id:containerId,APP_SUBDOMAIN});
      this.listContainers.filter(({id})=>parseInt(id)===parseInt(containerId)).forEach(container=>container.state="create_image");
    },
    onSelectionChange() {
      setTimeout(()=>{
        this.sidebarVisible = !!(this.selectedContainers && this.selectedContainers.length)
      });
    },
    unselectContainers() {
      this.selectedContainers = [];
      this.onSelectionChange();
    },
    async importSelectedSheets() {
      this.hideSelectSheets();
      let all = [];
      this.selectedSheets.forEach(sheet=>{
        let worksheet = this.datesWorkbook.Sheets[sheet.name]
        let data = xlsx.utils.sheet_to_json(worksheet);
        all = all.concat(data)
      });

      if (all.length) {
        all = all.map(item=>({
          ...item,
          APP_IMAGE:(this.selectedImage||{}).name,
          APP_SUBDOMAIN:item.APP_SUBDOMAIN||randomHex(8)
        }))
        await this.createContainers(all);
        this.loadUsers();
      }
    },
    hideSelectSheets() {
      this.displaySelectSheets = false;
    },
    updateUploadIcon(uploading=false) {
      this.uploadIcon = uploading ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    async handleUpload(e) {
      this.selectedSheets = [];
      const file = e.files[0];
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer);
      this.datesWorkbook = workbook;
      this.listSheets = workbook.SheetNames.map(name=>({name}));
      this.displaySelectSheets = true;
      this.updateUploadIcon(false);
      this.hideImportDialog();
    },
    editRow(row) {
      this.editingRow = row;
      this.displayRowEditor = true;
    },
    async downloadTemplate(image, filename='template-import.xlsm') {
      let response = await imageDownloadsService.get(image,filename);
      let blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
      );
      document.body.removeChild(link);
    },
    async viewUpLog(container) {
      let log = await containersService.getUpLog(container.id);
      this.displayUpLog = {container,log:log.data};
    }
  }
}
</script>
<style>
.p-sidebar.toolbar .p-sidebar-header {
  display: none;
}
.p-sidebar-bottom .p-sidebar.toolbar {
  height: auto;
}
.p-sidebar.toolbar .p-sidebar-content {
  padding: 0;
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
.p-dialog.remove-containers-dialog .p-dialog-content {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
</style>
