<template>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="p-datatable" v-if="enableQueries">
      <div class="p-datatable-header border-top-none flex flex-row justify-content-between align-items-center">
        <div>Meine Domains</div>
        <Button class="opacity-0"></Button>
      </div>
    </div>
    <div v-if="!softLock || profile.subscriptions || profile.level>1">
      <div v-if="enableQueries" class="mb-4">
        <InputText autofocus @keyup.enter="checkDomainAvailability(inputDomain)" placeholder="Gewünschte Domain" class="mt-2 ml-3" v-model="inputDomain"></InputText><Button @click="checkDomainAvailability(inputDomain)" class="p-button-success ml-1" :disabled="!inputDomain.length || checkingDomainInput" label="Suchen" :icon="checkingDomainInput ? 'pi pi-sync pi-spin' : 'pi pi-search'"></Button>
        <div class="ml-3 pt-2" v-if="domainAvailabilityResponse.length">
          <div :class="'p-2 border-1 inline-block '+(domainAvailabilityCode===210 ? 'border-green-600' : 'border-red-600')">
            {{domainAvailabilityResponse}}
          </div>
        </div>
      </div>
      <div>
        <div class="p-datatable">
          <div class="p-datatable-header border-top-none flex flex-row justify-content-between align-items-center">
            <div>DNS-Einträge</div>
            <Button label="Neuer&nbsp;Eintrag" icon="pi pi-plus" class="p-button-primary ml-1" @click="requestCreateDomain" autofocus></Button>
          </div>
        </div>
        <DomainsTableComponent :class="'external-domains-table'" :rows="data" :columns="mapColumns" :mapIcons="mapIcons" :mapEnums="mapEnum" :empty="'Keine Einträge vorhanden'" :buttons="buttons" :confirm-button="confirmButton" :key="trigger" :display-all-columns="false" :loading="loading" :fullscreen-table="false"></DomainsTableComponent>
      </div>
    </div>
    <div v-else class="m-2 flex flex-column">
      <div class="flex align-items-center">
        <i class="pi pi-times"></i><div class="ml-1">Für diese Funktion wird ein Abonnement benötigt.</div>
      </div>
      <a @click.prevent="$router.push('/account/subscriptions/'+plans[1]?.id)" class="mt-2 cursor-pointer no-underline text-blue-500"><i class="pi pi-arrow-up-right"></i>&nbsp;Upgrade</a>
    </div>
  </div>
  <ConfirmDialog group="confirm-domain" :class="'confirm-domain-dialog'">
    <template #message="slotProps">
      <div class="flex flex-column">
        <div class="flex flex-row align-items-start">
          <i :class="slotProps.message.icon+' text-primary'" style="font-size: 1.5rem"></i>
          <div class="pl-2" v-html="slotProps.message.message"></div>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import AuthView from "@/components/AuthView";
import domainsService from "@/domains-service";
import NewDomainDialog from "@/components/NewDomainDialog.vue";
import DomainsTableComponent from "@/components/DomainsTableComponent.vue";
import hexonetService from "@/hexonet-service";
import {singletons} from "../../shared";
import braintreeService from "@/braintree-service";

export default {
  extends: AuthView,
  name: 'DomainsView',
  components: {
    DomainsTableComponent
  },
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:0,
      data: [],
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Meine Einträge',url: '/my-domains'},
      ],
      mapColumns:{
        status:"Status",
        fqdn:"FQDN",
        type:"Typ",
        target:"Ziel",
      },
      mapIcons:{
        status:{
          0:{
            icon:"pi pi-clock",
            title:"In Bearbeitung"
          },
          1:{
            icon:"pi pi-check text-green-600",
            title:"Auflösung OK"
          },
          2:{
            icon:"pi pi-times text-red-600",
            title:"Fehler bei Auflösung"
          }
        },
      },
      userId:0,
      trigger:0,
      confirmButton:{
        onClick:(args)=>{
          this.requestConfirmDomain(args);
        }
      },
      buttons:[{
        icon:"pi pi-trash",
        onClick:(args)=>{
          this.requestRemoveDomain(args);
        }
      }],
      mapEnum: {
        type: {1:"A",2:"CNAME"},
      },
      inputDomain:"",
      checkingDomainInput:false,
      domainAvailabilityResponse:"",
      domainAvailabilityCode:0,
      profile: {},
      plans:[],
      softLock:false,
      enableQueries:false
    }
  },
  async created() {
    this.profile = await this.deferred;
    this.userId = this.profile.id;
    await this.loadData();
    ({data:this.plans} = await braintreeService.getPlans());
    this.softLock = parseInt(process.env.VUE_APP_UI_DOMAINS_SOFTLOCK)!==0;
    this.enableQueries = parseInt(process.env.VUE_APP_UI_DOMAINS_ENABLE_QUERIES)!==0;
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await domainsService.get(this.userId);
      let {root} = singletons.cluster;
      this.data = data.map(item=>({
        ...item,
        status:`${item.status}${item.reason}`,
        target:`${item.target}${root && item.targetNode ? ` (${item.targetNode.replace(/\..+$/,"")})` : ""}`
      }));
    },
    requestRemoveDomain(args) {
      this.$confirm.require({
        acceptClass:"p-button",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Eintrag löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await domainsService.delete(args.id);
            this.$toast.add({severity:'warn', summary: 'Eintrag löschen', detail:"Eintrag gelöscht.", life: 3000});
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    requestCreateDomain() {
      this.$dialog.open(NewDomainDialog, {
        props: {
          header: 'Neuer Eintrag',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '50vw',
            '640px': '80vw',
            '460px': '100vw'
          },
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            try {
              await domainsService.create({...data,userId:this.userId});
              this.$toast.add({severity:'success', summary: 'Neuer Eintrag', detail:"Eintrag erfolgreich hinzugefügt.", life: 3000});
              this.loadData();
            } catch (err) {
              const response = err.response?.data||{};
              this.$toast.add({severity:'error', summary: `Fehler ${response.status}`, detail:`${response.error}`, life: 3000})
            }
          }
        }
      });
    },
    requestConfirmDomain(args) {
      let {fqdn,type,target,id:domainId} = args;
      this.$confirm.require({
        props: {
          breakpoints:{
            '460px': '30vw'
          }
        },
        group:"confirm-domain",
        acceptClass:"p-button",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `<p class="mt-0">Bitte stellen Sie sicher, dass folgender Eintrag bei Ihrem DNS-Hoster konfiguriert wurde:<br><b class="code">${fqdn}&nbsp;&nbsp;&nbsp;&nbsp;${type>1 ? "CNAME" : "A"}&nbsp;&nbsp;&nbsp;&nbsp;${target}</b></p><p>Wenn Sie diesen Eintrag bestätigen, bevor die Auflösung möglich ist, kann dies die Verknüpfung mit Ihren Containern verzögern.</p>`,
        header: 'Externe DNS konfiguriert?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await domainsService.confirm(domainId);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err.response?.data?.message, life: 10000})
          }
          this.loadData();
        }
      });
    },
    async checkDomainAvailability(domain) {
      try {
        if (domain.match(/^[^.]{2,}\.[a-z]{2,}$/)) {
          this.checkingDomainInput=true;
          let {data:result} = await hexonetService.query("CheckDomain",{
            domain
          });
          this.checkingDomainInput=false;
          this.domainAvailabilityResponse=`${result.code}: ${result.description}`;
          this.domainAvailabilityCode = parseInt(result.code);
          console.log(result);
        }
      } catch (err) {
        //
      }
    }
  }
}
</script>
<style>
.external-domains-table thead {
  display:none
}
</style>