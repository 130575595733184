<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="searchValue" placeholder="Suche" @keyup.enter="onSearch" class="search"/>
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="searchValue" @click="searchValue=''; onSearch()"/>
        </span>
      </div>
      <Button v-if="profile.level>1" label="Import" :icon="uploadIcon" class="p-button-primary ml-1" @click="requestImportContainers"></Button>
      <Button v-if="profile.level>1 || profile.containerControls" label="Neuer&nbsp;Container" icon="pi pi-plus" class="p-button-primary ml-1" @click="requestCreateContainer"></Button>
    </div>
  </div>

  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <DataTable :value="listContainers" responsiveLayout="scroll" :class="'flex flex-column mb-7'" edit-mode="cell" :key="trigger" v-model:filters="mapFilters"
               v-model:selection="selectedContainers" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange">
      <template #empty>
        Keine Container verfügbar
      </template>
      <Column header="Status">
        <template #body="slotProps">
          <div>{{slotProps.data.state==='up' ? 'running' : 'off'}}</div>
        </template>
      </Column>
      <Column v-for="key in Object.keys(mapColumnsCategories)" :field="key" :header="mapColumnsCategories[key] || key" :key="key" class="p-cell-editing" :sortable="true">
        <template #body="{data,field}">
          <div v-if="key==='userId'">
            {{(listUsers.find(user=>user.id===data[field])||{}).username}}
          </div>
          <div v-else>
            {{data[field]}}
          </div>
        </template>
      </Column>
      <Column bodyStyle="text-align: right" class="column-button">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-external-link" :disabled="slotProps.data.state!=='up'" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="gotoSubdomain(slotProps.data)"/>
            <Button v-for="icon of listIconUrls" v-bind:key="icon" :icon="'pi pi-'+icon" :disabled="slotProps.data.state!=='up'" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="gotoSubdomain(slotProps.data,slotProps.data[icon])"/>
            <Button :icon="slotProps.data.state==='create_image' ? 'pi pi-sync pi-spin' : 'pi pi-camera'" :disabled="slotProps.data.state==='create_image' || !slotProps.data.image?.clonable" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="createImage(slotProps.data)" v-if="profile.level>1"/>
            <Button icon="pi pi-ellipsis-h" :class="'ml-1 p-button-rounded p-button-plain p-button-text p-button-lg'" @click="editRow(slotProps.data)"/>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog v-model:visible="displayRowEditor" :modal="true" :close-on-escape="true" :dismissable-mask="true" :closable="false" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Parameter</h3>
    </template>
    <div class="flex flex-column">
      <div v-for="key in Object.keys(editingRow.unpacked||{})" v-bind:key="key">
        <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2">
          <label for="name" class="w-2 flex-shrink-0 font-bold">{{key}}</label>
          <div class="flex-grow-1">
            {{editingRow[key]}}
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import ContainersView from "@/components/ContainersView.vue";
import containersService from "@/containers-service";

export default {
  extends: ContainersView,
  name: 'ContainersGroupView',
  data() {
    return {
      ...ContainersView.data.call(this),
      mapColumnsCategories:{
        userId:"Benutzer",
        APP_SUBDOMAIN:"Subdomain",
        APP_IMAGE:"Image",
        groups:"Gruppe"
      },
      requiredUserLevel:1
    }
  },
  methods: {
    ...ContainersView.methods,
    async loadData() {
      this.listContainers = ((await containersService.index({},"groups")).data||[]).map(item=>{item.groups=(item.groups||[]).join(","); return item;});
    }
  }
}
</script>
<style>
.p-sidebar.toolbar .p-sidebar-header {
  display: none;
}
.p-sidebar-bottom .p-sidebar.toolbar {
  height: auto;
}
.p-sidebar.toolbar .p-sidebar-content {
  padding: 0;
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
.p-dialog.remove-containers-dialog .p-dialog-content {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
</style>
