import http from "./http-auth";

class ImageDownloadsService {
  constructor(apiName="image-downloads") {
    this.apiName=apiName;
  }
  get(image,path) {
    return http.get(`/${this.apiName}/${image}/${path}`,{responseType:"blob"});
  }
}
export default new ImageDownloadsService();
