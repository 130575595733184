<template>
  <!-- display sidebar if current route is a descendant of a tab root -->
  <cf-container v-if="tabs.filter(tab=>$route.path.match(new RegExp('^'+tab.root))).length" :class="$route.path.match(/^\/((home)|(my))\/?$/) ? 'bg-home' : ''">
    <cf-sidebar class="active flex flex-column">
      <TabMenu :model="filteredTabs" @tab-change="onTabChange" :active-index="activeIndex" :class="'main-menu flex-grow-1'">
        <template #item="{item}">
          <a role="menuitem" class="p-menuitem-link" aria-label="Container" tabindex="0"><span :class="'p-menuitem-icon '+item.icon"></span><span class="p-menuitem-text" v-html="item.label"></span></a>
        </template>
      </TabMenu>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a target="_blank" class="p-menuitem-link" role="presentation" href="/dokumentation/hilfe/">
              <span class="p-menuitem-icon pi pi-question-circle"></span><span class="p-menuitem-text">Hilfe</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a class="p-menuitem-link" role="presentation" @click.prevent="logout" v-if="localProfile">
              <span class="p-menuitem-icon pi pi-sign-out"></span><span class="p-menuitem-text"><small style="display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:75px">{{localProfile.username}}</small><br>Abmelden</span>
            </a>
            <a class="p-menuitem-link" role="presentation" @click.prevent="login" v-else>
              <span class="p-menuitem-icon pi pi-sign-in"></span><span class="p-menuitem-text">Anmelden</span>
            </a>
          </li>
        </ul>
      </div>
    </cf-sidebar>
    <cf-content>
      <router-view></router-view>
    </cf-content>
  </cf-container>
  <cf-container v-else-if="$route.path.match(/^\/guests/)" :class="'bg-home'">
    <router-view></router-view>
  </cf-container>
  <router-view v-else></router-view>
  <ConfirmDialog></ConfirmDialog>
  <DynamicDialog></DynamicDialog>
  <Toast></Toast>
</template>
<script>
import {singletons} from "../shared";

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      tabs: [
        {label: 'Start', root:"/home", icon:"pi pi-home"},
        {label: '<small>Meine</small><br>Container', root:"/my", icon:"pi pi-sitemap", userlevel: 0},
        {label: '<small>Meine</small><br>Domains', root:"/my-domains", icon:"pi pi-globe", userlevel: 0},
        {label: '<small>Meine</small><br>Gruppen', root:"/my-groups", icon:"pi pi-users", userlevel: 1},
        {label: 'Account', root:"/account", icon:"pi pi-user", userlevel: 0},
        {label: 'System', root: '/system', icon:"pi pi-bolt", userlevel: 2},
      ],
      activeIndex:0,
      sidebarVisible:true,
      localProfile:null
    }
  },
  watch:{
    $route (){
      this.onRouteChange(this.$route.path);
    }
  },
  mounted() {
    this.onRouteChange(this.$route.path);
    function refreshVars() {
      let sidebar = document.querySelector("cf-sidebar");
      if (sidebar)
        document.documentElement.style.setProperty('--sidebar-width',`${sidebar.clientWidth}px`);
    }
    window.addEventListener('resize', () => {
      refreshVars()
    });
    // needed for reactivity (filteredTabs)
    window.addEventListener('auth-change',()=>{
      this.localProfile = this.authService.getLocalProfile();
    });
  },
  async created() {
    this.localProfile = this.authService.getLocalProfile();
  },
  methods: {
    onTabChange(e) {
      let tabs = this.getFilteredTabs();
      this.$router.push(this.activeIndex===e.index ? tabs[e.index].root  : tabs[e.index].path || tabs[e.index].root)
    },
    onRouteChange(currentPath) {
      let i=0;
      while (i<this.tabs.length && !currentPath.match(new RegExp(`^${this.tabs[i].root}([/]|$)`))) {
        i++;
      }
      if (i<this.tabs.length) {
        this.tabs[i].path = currentPath;
        this.activeIndex = i;
      }
    },
    async logout() {
      await this.authService.logout();
      singletons.sockets = {};
      this.login();
    },
    login() {
      this.$router.push("/login");
    },
    getFilteredTabs(tabs=this.tabs) {
      return tabs.filter(tab=>(tab.userlevel||-1)<=(this.localProfile ? this.localProfile.level||-1 : -1))
    }
  },
  computed: {
    filteredTabs() {
      return this.getFilteredTabs(this.tabs);
    }
  }
}
</script>
