<template>
  <DataTable :value="rows" :empty="empty" :mapEnums="mapEnums" :mapIcons="mapIcons" responsiveLayout="scroll" stripedRows :class="'flex flex-column'+(fullscreenTable ? ' flex-grow-1 fullscreen-table' : '')"
            v-model:filters="mapFilters" filterDisplay="menu" v-model:selection="selectedRows" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectAll()" @row-unselect-all="onSelectAll(false)"
            :loading="loading" loading-icon="pi pi-spinner text-8xl" :paginator="pagination" :rows="20" :rowsPerPageOptions="[10,20,50,rows.length-(rows.length%100)+100]" :page-link-size="5"
            rowGroupMode="rowspan" :groupRowsBy="groupRowsBy" @row-click="onRowClick($event)" :row-class="rowClass" @row-reorder="onRowReorder">
    <Column :row-reorder="true" v-if="reorderRows"></Column>
    <Column :selectionMode="selectionMode" v-if="selectionMode!=='none'" :class="selectionMode==='none' ? 'hide' : ''"></Column>
    <Column v-for="col of listColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden">
      <template #body="{data,field}" v-if="col.field==='status'">
        <Button v-if="!data.confirmed" :icon="'pi pi-check'" :label="'Bestätigen'" :class="'btn-primary'" @click="confirmButton.onClick(data)"/>
        <div v-else>
          <span :class="(mapIcons[field][(data[field]+'').replace(/^(\d+).*$/g,'$1')] || {}).icon||''" :title="(mapIcons[field][data[field]] || {}).title||''">&nbsp;</span>
          <span>{{(data[field]+'').replace(/^\d+/g,'')}}</span>
        </div>
      </template>
      <template #body="{data,field}" v-else-if="mapEnums[col.field]">{{mapEnums[field][data[field]]||""}}</template>
    </Column>
    <Column bodyStyle="text-align: right" v-for="button of buttons" :key="button.icon" class="column-button">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button v-if="!button.condition || button.condition(slotProps.data)" :icon="button.icon" :label="button.label" :class="button.class||'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="button.onClick(slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>{{empty}}</template>
  </DataTable>
</template>
<script>
import TableComponent from "@/components/TableComponent.vue";

export default {
  extends: TableComponent,
  name: "DomainsTableComponent",
  props:{
    confirmButton:Object
  },
  setup(props) {
    return {props}
  },
}
</script>