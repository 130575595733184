import http from "./http-auth";

class BraintreeService {
  constructor() {
    this.apiName="braintree";
  }
  getClientToken() {
    return http.post(`/${this.apiName}/token`);
  }
  checkout({nonce,amount}) {
    return http.post(`/${this.apiName}/checkout`,{nonce,amount});
  }
  subscribe({nonce,planId,firstName,lastName}) {
    return http.post(`/${this.apiName}/subscribe`,{nonce,planId,firstName,lastName});
  }
  getPlans() {
    return http.get(`/${this.apiName}/plans`);
  }
  getSubscriptions(planId) {
    return http.get(`/${this.apiName}/subscriptions/${encodeURIComponent(planId)}`);
  }
  cancelSubscription(subscriptionId) {
    return http.delete(`/${this.apiName}/subscriptions/${subscriptionId}`);
  }
  getPlanDetails(planId) {
    return http.get(`/${this.apiName}/plans/${planId}`);
  }
}
export default new BraintreeService();
