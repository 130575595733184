import http from "./http-auth";

class HexonetService {
  constructor() {
    this.apiName="hexonet";
  }
  toDashCase(str) {
    return str.substr(0,1).toLowerCase()+str.substr(1).replace(/[A-Z]/g,function(matches){
      return `-${matches[0].toLowerCase()}`;
    });
  }
  async query(command,params) {
    return http.post(`/${this.apiName}/${this.toDashCase(command)}`,params);
  }
}
export default new HexonetService();
