import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import LoginView from "@/components/LoginView";
import HomeView from "@/components/HomeView";
import UsersView from "@/components/UsersView";
import SystemView from "@/components/SystemView";
import UserView from "@/components/UserView";
import SettingsView from "@/components/SettingsView";
import ResetView from "@/components/ResetView";
import ResetRequestView from "@/components/ResetRequestView";
import PasswordExpiredView from "@/components/PasswordExpiredView";
import ContainersView from "@/components/ContainersView.vue";
import LaunchpadView from "@/components/LaunchpadView.vue";
import RegistrationView from "@/components/RegistrationView.vue";
import ConfirmationView from "@/components/ConfirmationView.vue";
import ConfirmationRequestView from "@/components/ConfirmationRequestView.vue";
import AccountView from "@/components/AccountView.vue";
import DomainsView from "@/components/DomainsView.vue";
import LaunchpadContainersView from "@/components/LaunchpadContainersView.vue";
import SystemDomainsView from "@/components/SystemDomainsView.vue";
import LogoutView from "@/components/LogoutView.vue";
import PaymentTestView from "@/components/PaymentTestView.vue";
import SubscriptionView from "@/components/SubscriptionView.vue";
import SSOView from "@/components/SSOView.vue";
import ContainersGroupView from "@/components/ContainersGroupView.vue";

const routes = [
    {
        path: '/',
        redirect: '/my'
    },
    {
        path: '/home',
        component: HomeView
    },
    {
        path: '/my',
        component: LaunchpadView
    },
    {
        path: '/my/:image',
        component: LaunchpadContainersView,
        props:true
    },
    {
      path: '/my/:image/:container',
      component: LaunchpadContainersView,
      props:true
    },
    {
        path: '/my-domains',
        component: DomainsView
    },
    {
        path: '/system',
        component: SystemView
    },
    {
        path: '/system/users',
        component: UsersView
    },
    {
        path: '/system/containers',
        component: ContainersView
    },
    {
        path: '/system/users/:id',
        component: UserView,
        props: true
    },
    {
        path: '/system/config',
        component: SettingsView
    },
    {
      path: '/system/domains',
      component: SystemDomainsView
    },
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '/logout',
        component: LogoutView
    },
    {
        path: '/reset',
        component: ResetRequestView
    },
    {
        path: '/reset/:uuid',
        component: ResetView,
        props: true
    },
    {
        path: '/password-expired/',
        component: PasswordExpiredView,
    },
    {
        path: '/confirmation',
        component: ConfirmationRequestView,
    },
    {
        path: '/confirmation/:uuid',
        component: ConfirmationView,
        props: true
    },
    {
        path: '/register',
        component: RegistrationView
    },
    {
        path: '/account',
        component: AccountView
    },
    {
        path: '/payment-test',
        component: PaymentTestView
    },
    {
        path: '/account/subscriptions/:planId',
        component: SubscriptionView,
        props: true
    },
    {
      path: '/sso/:accessToken/:refreshToken',
      component: SSOView,
      props:true
    },
    {
        path: '/my-groups',
        component: ContainersGroupView
    },
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
