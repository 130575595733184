<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <Listbox filter v-model="selectedDomain" :options="listDomains" optionLabel="fqdn" class="w-full" >
        <template #empty>
          <div class="flex flex-row justify-content-between">
            <div>Keine Domains verfügbar</div>
            <Button label="Anlegen" icon="pi pi-arrow-up-right" class="w-auto p-button-success flex flex-row p-2" @click="$router.push('/my-domains/') && closeDialog(false)">
              <i class="pi pi-arrow-up-right"></i>
              <div class="flex flex-column align-items-start ml-1">
                <small>Domain</small><small>anlegen</small>
              </div>
            </Button>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <i v-if="slotProps.option.id===preselectedDomainId" class="pi pi-check text-green-600 mr-1"/>
            <div>{{ slotProps.option.fqdn }}</div>
          </div>
        </template>
      </Listbox>
    </div>
  </div>
  <div class="flex flex-row mt-0 justify-content-between w-full">
    <Button :disabled="!preselectedDomainId" label="Löschen" icon="pi pi-trash" @click="requestRemove()" class="mr-2 p-button-danger"/>
    <div class="flex flex-row justify-content-end">
      <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text mr-2"/>
      <Button :disabled="!selectedDomain" label="Ok" icon="pi pi-check" @click="requestCloseDialog" autofocus />
    </div>
  </div>
</template>

<script>

import domainsService from "@/domains-service";

export default {
  inject: ['dialogRef'],
  name: "SelectDomainDialog",
  data() {
    return {
      listDomains:[],
      selectedDomain:null,
      preselectedDomainId:0
    }
  },
  async created() {
    let userId = (this.authService.getLocalProfile()||{}).id;
    ({data:this.listDomains} = await domainsService.get(userId));
    this.listDomains = this.listDomains.filter(({status})=>status===1)
    let params = this.dialogRef.data;
    if (params?.domainId) {
      this.selectedDomain = this.listDomains.find(domain=>domain.id === params.domainId);
      this.preselectedDomainId = params.domainId;
    }
  },
  methods: {
    closeDialog(ok=true) {
      this.dialogRef.close(ok ? {
        selectedDomain:this.selectedDomain
      } : null);
    },
    requestCloseDialog() {
      this.closeDialog();
    },
    requestRemove() {
      this.$confirm.require({
        acceptClass:"p-button",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher? Verknüpfung mit externer Domain wird aufgelöst und auf Standard-Subdomain zurückgesetzt.`,
        header: 'Verknüpfung löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          this.selectedDomain = {id:0};
          this.closeDialog();
        }
      });
    }
  }
}
</script>

<style scoped>

</style>