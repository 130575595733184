<script>
import DomainsView from "@/components/DomainsView.vue";
import domainsService from "@/domains-service";
import {singletons} from "../../shared";
import NewDomainAdminDialog from "@/components/NewDomainAdminDialog.vue";

export default {
  extends: DomainsView,
  name: 'SystemDomainsView',
  data() {
    return {
      ...DomainsView.data.call(this),
      mapColumns:{
        status:"Status",
        username:"Benutzer",
        fqdn:"FQDN",
        type:"Typ",
        target:"Ziel",
      }
    }
  },
  methods: {
    ...DomainsView.methods,
    async loadData() {
      let {data} = await domainsService.index();
      let {root} = singletons.cluster;
      this.data = data.map(item=>({
        ...item,
        status:`${item.status}${item.reason}`,
        target:`${item.target}${root && item.targetNode ? ` (${item.targetNode.replace(/\..+$/,"")})` : ""}`
      }));
    },
    requestCreateDomain() {
      this.$dialog.open(NewDomainAdminDialog, {
        props: {
          header: 'Neuer Eintrag',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '50vw',
            '640px': '80vw',
            '460px': '100vw'
          },
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            try {
              await domainsService.create({...data,userId:data.userId||this.userId});
              this.$toast.add({severity:'success', summary: 'Neuer Eintrag', detail:"Eintrag erfolgreich hinzugefügt.", life: 3000});
              this.loadData();
            } catch (err) {
              const response = err.response?.data||{};
              this.$toast.add({severity:'error', summary: `Fehler ${response.status}`, detail:`${response.error}`, life: 3000})
            }
          }
        }
      });
    },
  }
}
</script>