import CrudService from "./crud-service";
import http from "@/http-auth-autonode";

class ContainersService extends CrudService {
    start({list}, config) {
        return http.post(`/${this.apiName}/up`, list, config);
    }
    stop({list}, config) {
        return http.post(`/${this.apiName}/down`, list, config);
    }
    destroy({list}, config) {
        return http.post(`/${this.apiName}/destroy`, list, config);
    }
    getUpLog(containerId, config) {
        return http.get(`/${this.apiName}/logs/up/${containerId}`,config);
    }
    setContainerDomain(containerId,domainId) {
        return http.put(`/${this.apiName}/domain/${containerId}`, {domainId});
    }
}
export default new ContainersService("containers");