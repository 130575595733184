import ImagesService from "@/images-service";

export async function getInitArgs(selectedImage={},profile={}) {
    let initArgs = (await ImagesService.getInitArgs(selectedImage.name)).data;
    for (let key in initArgs) {
        try {
            initArgs[key] = (eval(initArgs[key]))(initArgs,profile);
        } catch (err) {
            // empty
        }
    }
    return initArgs;
}