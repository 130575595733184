<template>
  <div v-show="planExists">
    <div v-if="planExists" class="p-datatable">
      <div class="p-datatable-header border-top-none p-2 flex flex-row justify-content-between align-items-center">
        <div>{{mapPlans[planId]||planId}}</div>
        <Button @click="requestCancelSubscription" :disabled="!activeSubscriptions.length || cancelling" label="Stornieren" severity="secondary" :icon="cancelling ? 'pi pi-spin pi-spinner' : 'pi pi-times'" class="label-always"/>
      </div>
    </div>
    <i v-if="loading" class="pi pi-spinner pi-spin m-2"/>
    <div v-if="!activeSubscriptions.length" v-show="planExists" class="ml-1 flex-column flex align-items-center md:align-items-start">
      <div :class="loading ? 'hidden' : 'flex flex-column'" style="max-width: 400px;">
        <SelectButton @change="initPlanDetails" class="mt-2 flex flex-row" v-model="selectedPaymentOption" :options="paymentOptions" option-label="label" option-value="value" aria-labelledby="paymentOption" />
        <Markdown class="line-height-2" :source="planDetails.description" />
        <div :class="isGuest ? 'hidden' : 'flex flex-column mt-2'">
          <div v-for="(value,name) in mapBillingColumns" v-bind:key="name" >
            <div class="flex flex-column justify-content-start py-2">
              <label :for="name" class="w-2 flex-shrink-0">{{mapBillingColumns[name]||name}}</label>
              <InputText :id="name" type="text" v-model="billingAddress[name]" class="flex-grow-1" @input="onChangeBillingAddress" />
            </div>
          </div>
        </div>
        <div v-show="!isGuest" id="dropin-container"></div>
        <div :class="isGuest ? 'hidden' : 'flex flex-row justify-content-end'">
          <Button :icon="senderIcon" label="Abonnieren" @click="subscribe()">
            <i :class="senderIcon"></i>
            <div class="flex flex-column align-items-start ml-2">
              <small><b>Abonnieren</b></small><div>{{planDetails.currencyIsoCode}} {{planDetails.price}}/{{selectedPaymentOption==='m' ? 'Monat' : 'Jahr'}}</div>
            </div>
          </Button>
        </div>
        <div v-if="isGuest">
          <div class="flex align-items-center">
            <div class="ml-1">Nutzerkonto erforderlich </div><a @click.prevent="requestRegister" class="ml-2 cursor-pointer no-underline text-blue-500"><i class="pi pi-arrow-up-right"></i>&nbsp;Register</a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!loading">
      <div v-if="!activeSubscriptions.length && subscriptions.length" class="mt-6">
        <h4>Frühere Abonnemente</h4>
      </div>
      <div class="mt-3" v-for="s of subscriptions" :key="s.id">
        <div v-if="!s.cancelled" class="text-primary flex flex-row align-items-center">
          <div><i class="pi pi-check"></i> Aktiv</div>
        </div>
        <div v-else-if="!s.void"><i class="pi pi-clock"></i> Storniert (End-Datum: {{new Date(s.validUntil).toISOString().slice(8, 10) + '.' + new Date(s.validUntil).toISOString().slice(5, 7) + '.' + new Date(s.validUntil).toISOString().slice(0, 4)}})</div>
        <div v-else><i class="pi pi-times"></i> Beendet ({{new Date(s.validUntil).toISOString().slice(8, 10) + '.' + new Date(s.validUntil).toISOString().slice(5, 7) + '.' + new Date(s.validUntil).toISOString().slice(0, 4)}})</div>
      </div>
    </div>
  </div>
</template>

<script>
import braintreeService from "@/braintree-service";
import Markdown from "vue3-markdown-it";
import RegistrationDialog from "@/components/RegistrationDialog.vue";
import AuthGuestView from "@/components/AuthGuestView.vue";

export default {
  components: {Markdown},
  extends: AuthGuestView,
  name: 'SubscriptionView',
  props: {
    planId:String
  },
  data() {
    return {
      requiredUserLevel:0,
      data: {},
      loading:true,
      senderIcon:"",
      paymentInstance:null,
      profile:{},
      planExists:false,
      plans:[],
      mapPlans:{},
      subscriptions:[],
      activeSubscriptions:[],
      cancelling:false,
      planDetails:{},
      paymentOptions:[{label:"Monatlich",value:"m"}, {label:"Jährlich",value:"y"}],
      selectedPaymentOption:"m",
      billingAddress:{},
      mapBillingColumns:{
        givenName:"Vorname",
        surname:"Nachname",
        streetAddress:"Strasse+Nr",
        postalCode:"Postleitzahl"
      },
      isGuest:false
    }
  },
  async mounted() {
    this.updateSenderIcon();
    this.profile = await this.deferred;
    this.isGuest = (this.profile?.username||"").match(/^guest_/);
    ({data:this.plans} = await braintreeService.getPlans());
    this.mapPlans = {};
    for (let {id,name} of this.plans) {
      this.mapPlans[id] = name;
    }
    this.planExists = typeof this.mapPlans[this.planId] !== "undefined";
    if ((this.profile.subscriptions||"").split(",").filter(s=>s).map(s=>{
      return s.trim().replace(/-[my]$/,"");
    }).indexOf(this.planId)===-1) {
      const dropin = require('braintree-web-drop-in');
      let {data:authorization} = await braintreeService.getClientToken();
      this.paymentInstance = await dropin.create({
        authorization,
        container: '#dropin-container',
        threeDSecure: true,
        locale: process.env?.VUE_APP_BRAINTREE_LOCALE
      });
    }
    await this.init();
    await this.initPlanDetails();
    try {
      let args = JSON.parse(this.profile.jsonData)
      this.billingAddress = {
        givenName:args.firstName,
        surname:args.lastName
      }
    } catch (err) { /**/ }
    this.loading = false;
  },
  methods: {
    async initPlanDetails() {
      if (this.planExists) {
        this.loading = true;
        ({data:this.planDetails} = await braintreeService.getPlanDetails(`${this.planId}-${this.selectedPaymentOption}`));
        this.loading = false;
      }
    },
    async init() {
      ({data:this.subscriptions} = await braintreeService.getSubscriptions(`${this.planId}-%`));
      this.activeSubscriptions = this.subscriptions.filter(s=>!s.void);
    },
    validateBillingAddress() {
      let missing = [];
      for (let key in this.mapBillingColumns) {
        if (!(this.billingAddress[key]||"").trim())
          missing.push(this.mapBillingColumns[key]);
      }
      if (missing.length) {
        this.$toast.add({severity:'error', summary: 'Fehlende Angaben', detail:`Bitte Angaben des Karteninhabers vervollständigen: ${missing.join(", ")}`, life: 5000});
        return false;
      }
      return true;
    },
    async subscribe(planId=this.planId) {
      if (this.validateBillingAddress()) {
        this.updateSenderIcon(true);
        let result = await new Promise((resolve)=>{
          this.paymentInstance.requestPaymentMethod({
            threeDSecure: {
              amount:this.planDetails.price,
              billingAddress:this.billingAddress
            }
          },async (error, payload) => {
            if (error) {
              this.$toast.add({severity:'error', summary:error.name , detail:error.message||"Fehler", life: 5000});
              resolve();
            }
            else {
              let {data} = await braintreeService.subscribe({
                nonce:payload.nonce,
                planId:`${planId}-${this.selectedPaymentOption}`,
                firstName:this.billingAddress.givenName,
                lastName:this.billingAddress.surname
              });
              resolve(data);
            }
          });
        });
        if (result && result.success)
          this.$toast.add({severity:'success', summary: 'Abonnement', detail:result.message||"erfolgreich abgeschlossen", life: 5000});
        else if (result && result.error)
          this.$toast.add({severity:'error', summary: 'Abonnement', detail:result.error||"Fehler", life: 5000})
        this.updateSenderIcon();
        this.init();
      }
    },
    updateSenderIcon(sending=false) {
      this.senderIcon = sending ? "pi pi-sync pi-spin" : "pi pi-credit-card";
    },
    requestCancelSubscription() {
      this.$confirm.require({
        acceptClass:"p-button",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Abonnement stornieren',
        icon: 'pi pi-question-circle',
        accept: async () => {
          this.cancelling=true;
          try {
            if (this.activeSubscriptions.length) {
              let {data} = await braintreeService.cancelSubscription(this.activeSubscriptions[0].id);
              let {success,message}=data;
              if (success)
                this.$toast.add({severity:'warn', summary: 'Abonnement stornieren', detail:message||"Abonnement storniert", life: 5000});
              else
                this.$toast.add({severity:'error', summary: 'Fehler', detail:message, life: 5000})
            }
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 5000})
          }
          this.cancelling=false;
          this.init(true);
        }
      });
    },
    onChangeBillingAddress() {

    },
    requestRegister() {
      this.$dialog.open(RegistrationDialog, {
        props: {
          header: 'Registrieren',
          modal: true,
          contentClass:"dialog-register",
          dismissableMask:true
        },
        onClose: ({data}) => {
          if (data?.username) {
            this.$toast.add({severity:'success', summary: 'Registration', detail:`Eingeloggt als '${data.username}'`, life: 5000});
            this.isGuest = false;
          }
        }
      });
    },
  }
}
</script>
<style>
.braintree-placeholder {
  display: none;
}
</style>