<template :key="key">
  <div class="p-datatable"><div class="p-datatable-header border-top-none">Benutzer</div></div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">Benutzername</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      {{profile.username}}
    </div>
  </div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">E-Mail</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      {{profile.email}}
    </div>
  </div>
  <div v-if="plans.length">
    <div class="p-datatable"><div class="p-datatable-header border-top-none mt-4">Abonnement</div></div>
    <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
      <label class="font-normal lg:w-4">
        <div v-if="!subscriptions.length" class="flex flex-row align-items-center">
          <div>{{ plans[0]?.name }}</div>
          <a @click.prevent="$router.push('/account/subscriptions/'+plans[1].id)" class="cursor-pointer ml-2 no-underline text-blue-500"><i class="pi pi-arrow-up-right"></i>&nbsp;Upgrade</a>
        </div>
        <div v-else>
          <a v-for="s of subscriptions" :key="s" class="cursor-pointer no-underline text-blue-500" @click.prevent="$router.push('/account/subscriptions/'+s.replace(/-[my]$/,''))">{{ (mapPlans[s] || s).replace(/-[my]$/,'') }}</a>
        </div>
      </label>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-4">Cluster</div></div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">Node</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      {{profile.node}}
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-4">Quota</div></div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">Anwendungen</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      <i v-if="loading" class="pi pi-spinner pi-spin"></i>
      <span v-else>{{accountStats.used?.count||0}} / {{accountStats.quota?.count||0}}</span>
    </div>
  </div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">Disk</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      <i v-if="loading" class="pi pi-spinner pi-spin"></i>
      <span v-else>{{parseFloat(accountStats.used?.disk||0).toFixed(1)}}G / {{parseFloat(accountStats.quota?.disk||0).toFixed(1)}}G</span>
    </div>
  </div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label for="name" class="font-bold lg:w-4">Memory</label>
    <div class="flex-grow-1 max-w-full lg:max-w-20rem">
      <i v-if="loading" class="pi pi-spinner pi-spin"></i>
      <span v-else>{{parseFloat(accountStats.used?.memory||0).toFixed(1)}}M / {{accountStats.quota?.memory||0}}M</span>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-4">Service Level</div></div>
  <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center ml-3 mt-2">
    <label class="font-normal lg:w-4">
      <i v-if="loading" class="pi pi-spinner pi-spin"></i>
      <a v-else-if="accountStats.sla" :href="accountStats.sla.url" target="_blank" class="no-underline text-blue-500"><i class="pi pi-arrow-up-right"></i>&nbsp;{{accountStats.sla.name}}</a>
      <span v-else>-</span>
    </label>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import AccountService from "@/account-service";
import braintreeService from "@/braintree-service";

export default {
  extends: AuthView,
  name: 'AccountView',
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:0,
      data: {},
      loading:true,
      mapColumns:{
        username:"Benutzername",
        email:"Email",
        level:"Userlevel",
        confirmation:"Freischaltung"
      },
      mapSettings:{},
      booleanSelection:[{name:'nein', id:'false'},{name:'ja', id:'true'}],
      senderIcon:"",
      profile:{},
      accountStats:{},
      usedMemory:0,
      key:1,
      plans:[],
      mapPlans:{},
      subscriptions:[]
    }
  },
  async created() {
    this.profile = await this.deferred;
    this.subscriptions = (this.profile.subscriptions||"").split(",").filter(s=>s).map(s=>s.trim());
    ({data:this.plans} = await braintreeService.getPlans());
    this.mapPlans = {};
    for (let {id,name} of this.plans) {
      this.mapPlans[id] = name;
    }
    this.accountStats = (await AccountService.getStats()).data;
    this.key++;
    this.loading=false;
    console.log(this.plans);
  },
}
</script>
