<template>
  <div class="flex-grow-1 flex flex-column align-items-center">
    <div v-if="isGuest" class="p-3 mx-2 mt-2 mb-0 bg-white-alpha-80 relative">
      <i class="pi pi-exclamation-triangle text-xl text-blue-500 relative" style="top: 3px;"></i> Ohne Registration erstellte Container werden innert 24 Stunden gelöscht.
      <a class="text-blue-500 inline-flex flex-row justify-content-center no-underline" href="/register"><i class="pi pi-caret-right"></i> Registrieren</a>
    </div>
    <div class="flex justify-content-center align-items-center flex-grow-1 flex-wrap">
      <div v-for="image of listImages" :key="image.name" class="cursor-pointer px-2 lg:px-4 shadow-0 lg:shadow-2 border-black-alpha-30 m-2 md:m-3 bg-white-alpha-90"
           @click="goto('/my/'+image.name)">
        <div class="text-center mb-1 mt-2 lg:mt-3">
          <div class="flex flex-row justify-content-center align-items-center mb-1">
            <div class="text-900 text-xl md:text-3xl font-medium line-height-1">
              <img v-if="image.iconBase64" :src="'data:image/png;base64,'+image.iconBase64" :alt="image.name" style="width:128px"/>
            </div>
  <!--
            <div class="absolute">
              <i class="pi pi-sync pi-spin text-white text-6xl font-normal" style="mix-blend-mode:exclusion"></i>
            </div>
  -->
          </div>
          <div class="font-medium text-sm md:text-base line-height-2 md:line-height-3">
            {{image.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagesPublicService from "@/images-public-service";
import AuthGuestView from "@/components/AuthGuestView.vue";

export default {
  extends: AuthGuestView,
  name: 'LaunchpadView',
  props: {
    msg: String
  },
  data() {
    return {
      listImages:[],
      listContainers:[],
      profile:{},
      isGuest:false
    }
  },
  async created() {
    this.profile = await this.deferred;
    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    this.isGuest = this.profile.username?.match(/^guest_/);
    this.listImages = await new ImagesPublicService().index();
    if (this.listImages.length===1)
      this.goto(`/my/${this.listImages[0].name}`);
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul>li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
